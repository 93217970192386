import { default as confirmation8ZDKcvqwneMeta } from "/tmp/build/80754af9/customer-website/pages/account-settings/auth/confirmation.vue?macro=true";
import { default as indexd62wOmuj3fMeta } from "/tmp/build/80754af9/customer-website/pages/account-settings/auth/index.vue?macro=true";
import { default as indexh71oHhFiNSMeta } from "/tmp/build/80754af9/customer-website/pages/account-settings/index.vue?macro=true";
import { default as indexPsYIDLNcIZMeta } from "/tmp/build/80754af9/customer-website/pages/communication-preferences/index.vue?macro=true";
import { default as mapTyy5m0HWjqMeta } from "/tmp/build/80754af9/customer-website/pages/embed/map.vue?macro=true";
import { default as legal_45noticeOhPvZfPVAdMeta } from "/tmp/build/80754af9/customer-website/pages/legal-notice.vue?macro=true";
import { default as privacy_45policyeOVB9kvO1qMeta } from "/tmp/build/80754af9/customer-website/pages/privacy-policy.vue?macro=true";
import { default as confirmationIBxSzYrBWvMeta } from "/tmp/build/80754af9/customer-website/pages/profile/auth/confirmation.vue?macro=true";
import { default as indexcHVsglkO63Meta } from "/tmp/build/80754af9/customer-website/pages/profile/auth/index.vue?macro=true";
import { default as discoverfPY7FL1tKxMeta } from "/tmp/build/80754af9/customer-website/pages/profile/discover.vue?macro=true";
import { default as indexunZFGcNyjxMeta } from "/tmp/build/80754af9/customer-website/pages/profile/index.vue?macro=true";
import { default as _91id_93wAcuROkNTQMeta } from "/tmp/build/80754af9/customer-website/pages/profile/offers/[id].vue?macro=true";
import { default as index0QrJ4L7kqhMeta } from "/tmp/build/80754af9/customer-website/pages/profile/offers/index.vue?macro=true";
import { default as indexBwtG4fXanfMeta } from "/tmp/build/80754af9/customer-website/pages/profile/payments/index.vue?macro=true";
import { default as confirmationPcAp8ARuk2Meta } from "/tmp/build/80754af9/customer-website/pages/profile/register/confirmation.vue?macro=true";
import { default as identityJbrY64mQZuMeta } from "/tmp/build/80754af9/customer-website/pages/profile/register/identity.vue?macro=true";
import { default as indexi3wew4TKFwMeta } from "/tmp/build/80754af9/customer-website/pages/profile/register/index.vue?macro=true";
import { default as payment_45method3nQP7g6AyGMeta } from "/tmp/build/80754af9/customer-website/pages/profile/register/payment-method.vue?macro=true";
import { default as transport_45card9RlWSD30ZtMeta } from "/tmp/build/80754af9/customer-website/pages/profile/register/transport-card.vue?macro=true";
import { default as tripsByUUdQ1ePqMeta } from "/tmp/build/80754af9/customer-website/pages/profile/trips.vue?macro=true";
import { default as leasing4YjXntbFexMeta } from "/tmp/build/80754af9/customer-website/pages/referral/leasing.vue?macro=true";
import { default as sharingK0pTMmO0EFMeta } from "/tmp/build/80754af9/customer-website/pages/referral/sharing.vue?macro=true";
import { default as _91slug_93q1Evtha8VGMeta } from "/tmp/build/80754af9/customer-website/pages/showcase/[slug].vue?macro=true";
import { default as aboutzqAVb6lESnMeta } from "/tmp/build/80754af9/customer-website/pages/showcase/about.vue?macro=true";
import { default as _91slug_93Vx0z9ZtCcLMeta } from "/tmp/build/80754af9/customer-website/pages/showcase/blog/[slug].vue?macro=true";
import { default as indexRr1FSNwqodMeta } from "/tmp/build/80754af9/customer-website/pages/showcase/blog/index.vue?macro=true";
import { default as helpHM8PuX5VGxMeta } from "/tmp/build/80754af9/customer-website/pages/showcase/help.vue?macro=true";
import { default as index6h2mJtJlvpMeta } from "/tmp/build/80754af9/customer-website/pages/showcase/index.vue?macro=true";
import { default as leasing63tkVvjVw6Meta } from "/tmp/build/80754af9/customer-website/pages/showcase/leasing.vue?macro=true";
import { default as map8udadoa67aMeta } from "/tmp/build/80754af9/customer-website/pages/showcase/map.vue?macro=true";
import { default as sharingl0HH76BPADMeta } from "/tmp/build/80754af9/customer-website/pages/showcase/sharing.vue?macro=true";
import { default as terms_45and_45conditionssDfjkVw8QzMeta } from "/tmp/build/80754af9/customer-website/pages/terms-and-conditions.vue?macro=true";
import { default as component_45stubFWwkCwXXR1Meta } from "/tmp/build/80754af9/customer-website/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubFWwkCwXXR1 } from "/tmp/build/80754af9/customer-website/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-settings-auth-confirmation",
    path: "/account-settings/auth/confirmation",
    meta: confirmation8ZDKcvqwneMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/account-settings/auth/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "account-settings-auth",
    path: "/account-settings/auth",
    meta: indexd62wOmuj3fMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/account-settings/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account-settings",
    meta: indexh71oHhFiNSMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: "communication-preferences",
    path: "/communication-preferences",
    meta: indexPsYIDLNcIZMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/communication-preferences/index.vue").then(m => m.default || m)
  },
  {
    name: "embed-map",
    path: "/embed/map",
    meta: mapTyy5m0HWjqMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/embed/map.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-legal-notice",
    path: "/legal-notice",
    meta: legal_45noticeOhPvZfPVAdMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyeOVB9kvO1qMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-auth-confirmation",
    path: "/profile/auth/confirmation",
    meta: confirmationIBxSzYrBWvMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/auth/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "profile-auth",
    path: "/profile/auth",
    meta: indexcHVsglkO63Meta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-discover",
    path: "/profile/discover",
    meta: discoverfPY7FL1tKxMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/discover.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexunZFGcNyjxMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-offers-id",
    path: "/profile/offers/:id()",
    meta: _91id_93wAcuROkNTQMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/offers/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-offers",
    path: "/profile/offers",
    meta: index0QrJ4L7kqhMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: indexBwtG4fXanfMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-register-confirmation",
    path: "/profile/register/confirmation",
    meta: confirmationPcAp8ARuk2Meta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/register/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "profile-register-identity",
    path: "/profile/register/identity",
    meta: identityJbrY64mQZuMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/register/identity.vue").then(m => m.default || m)
  },
  {
    name: "profile-register",
    path: "/profile/register",
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/register/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-register-payment-method",
    path: "/profile/register/payment-method",
    meta: payment_45method3nQP7g6AyGMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/register/payment-method.vue").then(m => m.default || m)
  },
  {
    name: "profile-register-transport-card",
    path: "/profile/register/transport-card",
    meta: transport_45card9RlWSD30ZtMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/register/transport-card.vue").then(m => m.default || m)
  },
  {
    name: "profile-trips",
    path: "/profile/trips",
    meta: tripsByUUdQ1ePqMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/profile/trips.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-referral-leasing",
    path: "/referral/leasing",
    meta: leasing4YjXntbFexMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/referral/leasing.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-referral-sharing",
    path: "/referral/sharing",
    meta: sharingK0pTMmO0EFMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/referral/sharing.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-showcase-slug",
    path: "/:slug()",
    meta: _91slug_93q1Evtha8VGMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/[slug].vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-showcase-about",
    path: "/about",
    meta: aboutzqAVb6lESnMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/about.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-showcase-blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93Vx0z9ZtCcLMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-showcase-blog",
    path: "/blog",
    meta: indexRr1FSNwqodMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-showcase-help",
    path: "/help",
    meta: helpHM8PuX5VGxMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/help.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-showcase",
    path: "/",
    meta: index6h2mJtJlvpMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/index.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-showcase-leasing",
    path: "/leasing",
    meta: leasing63tkVvjVw6Meta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/leasing.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-showcase-map",
    path: "/map",
    meta: map8udadoa67aMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/map.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-showcase-sharing",
    path: "/sharing",
    meta: sharingl0HH76BPADMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/sharing.vue").then(m => m.default || m)
  },
  {
    name: "unlocalized-terms-and-conditions",
    path: "/terms-and-conditions",
    meta: terms_45and_45conditionssDfjkVw8QzMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "legal-notice",
    path: "/:locale(ast|de|en|es|fr|it|pt)/legal-notice",
    meta: legal_45noticeOhPvZfPVAdMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/:locale(ast|de|en|es|fr|it|pt)/privacy-policy",
    meta: privacy_45policyeOVB9kvO1qMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "referral-leasing",
    path: "/:locale(ast|de|en|es|fr|it|pt)/referral/leasing",
    meta: leasing4YjXntbFexMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/referral/leasing.vue").then(m => m.default || m)
  },
  {
    name: "referral-sharing",
    path: "/:locale(ast|de|en|es|fr|it|pt)/referral/sharing",
    meta: sharingK0pTMmO0EFMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/referral/sharing.vue").then(m => m.default || m)
  },
  {
    name: "showcase-slug",
    path: "/:locale(ast|de|en|es|fr|it|pt)/:slug()",
    meta: _91slug_93q1Evtha8VGMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/[slug].vue").then(m => m.default || m)
  },
  {
    name: "showcase-about",
    path: "/:locale(ast|de|en|es|fr|it|pt)/about",
    meta: aboutzqAVb6lESnMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/about.vue").then(m => m.default || m)
  },
  {
    name: "showcase-blog-slug",
    path: "/:locale(ast|de|en|es|fr|it|pt)/blog/:slug()",
    meta: _91slug_93Vx0z9ZtCcLMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "showcase-blog",
    path: "/:locale(ast|de|en|es|fr|it|pt)/blog",
    meta: indexRr1FSNwqodMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "showcase-help",
    path: "/:locale(ast|de|en|es|fr|it|pt)/help",
    meta: helpHM8PuX5VGxMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/help.vue").then(m => m.default || m)
  },
  {
    name: "showcase",
    path: "/:locale(ast|de|en|es|fr|it|pt)/",
    meta: index6h2mJtJlvpMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/index.vue").then(m => m.default || m)
  },
  {
    name: "showcase-leasing",
    path: "/:locale(ast|de|en|es|fr|it|pt)/leasing",
    meta: leasing63tkVvjVw6Meta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/leasing.vue").then(m => m.default || m)
  },
  {
    name: "showcase-map",
    path: "/:locale(ast|de|en|es|fr|it|pt)/map",
    meta: map8udadoa67aMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/map.vue").then(m => m.default || m)
  },
  {
    name: "showcase-sharing",
    path: "/:locale(ast|de|en|es|fr|it|pt)/sharing",
    meta: sharingl0HH76BPADMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/showcase/sharing.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions",
    path: "/:locale(ast|de|en|es|fr|it|pt)/terms-and-conditions",
    meta: terms_45and_45conditionssDfjkVw8QzMeta || {},
    component: () => import("/tmp/build/80754af9/customer-website/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: component_45stubFWwkCwXXR1Meta?.name,
    path: "/profile/edit",
    component: component_45stubFWwkCwXXR1
  }
]